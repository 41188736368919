.ant-input {
  height: 35px;
  font-size: 16px;
}

.ant-input-error {
  font-size: 12px;
  color: #ff4d4f;
}

@font-family: "Gotham", sans-serif;@primary-color: #e95f20;@tooltip-bg: #e95f20;