@import "~antd/lib/tabs/style/index.less";
@import "../variables.less";

.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: visible;
}

.ant-layout-sider-light {
  background: transparent;
}

.ant-layout.ant-layout-has-sider {
  padding: 0 26px 20px;
  border-radius: 0 4px 4px 4px;
}

@font-family: "Gotham", sans-serif;@primary-color: #e95f20;@tooltip-bg: #e95f20;