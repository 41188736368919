@import "~antd/lib/checkbox/style/index.less";
@import "~antd/lib/radio/style/index.less";
@import "../variables.less";

.ant-radio-checked .ant-radio-inner {
  border-color: @primary-color !important;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: @primary-color;
}

@font-family: "Gotham", sans-serif;@primary-color: #e95f20;@tooltip-bg: #e95f20;