@import "~antd/lib/tabs/style/index.less";
@import "../variables.less";

.ant-tabs-top > .ant-tabs-nav {
  margin: 0;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  align-items: flex-start;
  border-radius: 4px;
  height: 40px;
  padding: 12px 26px;
  background-color: @background-color-not-active;
  border-color: @border-color-not-active;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type) {
  border-radius: 4px 4px 0 0;
  margin-right: 0px !important;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  //height: 45px;
  background-color: @background-color;
  border-color: @border-color;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #f7f8fa !important;
  //   box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  position: relative;
  // transform: scale(1.2);
  z-index: 2;
  // & ~.ant-tabs-tab {
  //   transform: translateX(25px);
  // }
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  align-items: flex-end;
}

.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav > .tt {
  margin: -1px 0 !important;
}

.ant-tabs-nav {
  z-index: 20;
}

.ant-layout.ant-layout-has-sider {
  background: linear-gradient(#f7f8fa, #e6e6ea, #f7f8fa);
  padding: 24px 26px;
  border-radius: 4px;
  border: 1px solid @border-color;
  // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); this should be check if it should be commented or not
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs {
  overflow: visible !important;
}

.ant-tabs {
  &-content-holder {
    z-index: 3;
  }
  &-tab {
    transform-origin: bottom left;
  }
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  box-shadow: none !important;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before,
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before,
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 0 !important;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  border-color: #9ba2aa !important;
}

@font-family: "Gotham", sans-serif;@primary-color: #e95f20;@tooltip-bg: #e95f20;