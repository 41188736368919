@import "../variables.less";
.bin {
  fill: @border-color;
  transition: 0.3s ease-in;

  &:hover {
    fill: @primary-color;
  }
}

.text {
  fill: @border-color;
  cursor: pointer;
  font-size: 10px;
}

@font-family: "Gotham", sans-serif;@primary-color: #e95f20;@tooltip-bg: #e95f20;