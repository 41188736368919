.TabPanelOfferDetailClass {
  &.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab {
    border: 1px solid #d3d3d3 !important;
    border-bottom: 0 !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 12%), 0 1px 2px rgba(0, 0, 0, 24%) !important;
    margin-bottom: 2px !important;
  }
}

.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab {
  border-color: #9ba2aa !important;
  border-width: 10px;
}

@font-family: "Gotham", sans-serif;@primary-color: #e95f20;@tooltip-bg: #e95f20;