@font-face {
  font-family: "Gotham";
  src: url("./fonts/gotham/GothamPro-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/gotham/GothamPro.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/gotham/GothamPro-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/gotham/GothamPro-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/gotham/GothamPro-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}

/* Gotham italic */
@font-face {
  font-family: "Gotham";
  src: url("./fonts/gotham/GothamPro-LightItalic.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/gotham/GothamPro-Italic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/gotham/GothamPro-MediumItalic.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/gotham/GothamPro-BoldItalic.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/gotham/GothamPro-BlackItalic.woff2") format("woff2");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "NotoSans";
  src: url("./fonts/calibri/NotoSans-Light.ttf") format("ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "NotoSans";
  src: url("./fonts/calibri/NotoSans-Regular.ttf") format("ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "NotoSans";
  src: url("./fonts/calibri/NotoSans-Medium.ttf") format("ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "NotoSans";
  src: url("./fonts/calibri/NotoSans-Bold.ttf") format("ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "NotoSans";
  src: url("./fonts/calibri/NotoSans-Black.ttf") format("ttf");
  font-weight: 900;
  font-style: normal;
}

/* NotoSans italic */
@font-face {
  font-family: "NotoSans";
  src: url("./fonts/calibri/NotoSans-LightItalic.ttf") format("ttf");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "NotoSans";
  src: url("./fonts/calibri/NotoSans-Italic.ttf") format("ttf");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "NotoSans";
  src: url("./fonts/calibri/NotoSans-MediumItalic.ttf") format("ttf");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "NotoSans";
  src: url("./fonts/calibri/NotoSans-BoldItalic.ttf") format("ttf");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "NotoSans";
  src: url("./fonts/calibri/NotoSans-BlackItalic.ttf") format("ttf");
  font-weight: 900;
  font-style: italic;
}

