@import "./fonts.less";
@import "./components/variables.less";
@import "~nprogress/nprogress.css";

#nprogress .bar {
  background: @primary-color !important;
}

#nprogress .spinner-icon {
  border-top-color: @primary-color !important;
  border-left-color: @primary-color !important;
}

body {
  min-width: 1400px;
  font-family: "Noto Sans", sans-serif;
}

.ant-popconfirm {
  min-width: 300px;
}

.ant-image-preview-mask {
  background-color: rgba(0, 0, 0, 0.85) !important;
}


@font-family: "Gotham", sans-serif;@primary-color: #e95f20;@tooltip-bg: #e95f20;