@import "~antd/lib/button/style/index.less";
@import "../variables.less";

.ant-btn.ant-btn-primary,
.ant-btn.ant-btn-primary.ant-btn-sm {
  background-color: @primary-color;
  border-color: @primary-color;

  &:hover {
    background-color: @orange-2;
    border-color: @orange-2;
  }

  &:focus {
    background-color: @primary-color;
    border-color: @primary-color;
  }
}

@font-family: "Gotham", sans-serif;@primary-color: #e95f20;@tooltip-bg: #e95f20;